<template>
  <ul class="action-list" v-if="action === 'modify'">
    <li class="action-item" v-for="entity in entities" :key="entity._id">
      <div>
        Changed:
        <span class="modify-field">{{ entity.column }}</span>
      </div>
      <div>
        Old Data:
        <span class="modify-field old-value">{{
          getFormattedValue(entity.column, entity.oldValue)
        }}</span>
      </div>
      <div>
        New Data:
        <span class="modify-field new-value">{{
          getFormattedValue(entity.column, entity.newValue)
        }}</span>
      </div>
    </li>
  </ul>
  <div v-else-if="action === 'comment created' || action === 'comment closed'">
    TODO: create correct logs for comments
  </div>
  <div class="action-list" v-else>
    <span class="capitalize">{{ action }}</span>
    {{ entities.length }} row(s) for
    {{ getProjectName(entities[0]) }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      formattingFunctions: {
        deadline: (value) => new Date(value).toLocaleDateString('en-US')
      }
    }
  },
  props: ['entities', 'action'],
  methods: {
    getProjectName(entity) {
      let value
      if (entity.oldValue) {
        value = entity.oldValue
      } else if (entity.newValue) {
        value = entity.newValue
      } else {
        return "can't find project."
      }
      value = JSON.parse(value)
      return `${value.project.abbr} - ${value.project.type} project`
    },
    getFormattedValue(column, value) {
      if (!value) {
        return 'No Data'
      }
      if (this.formattingFunctions[column]) {
        return this.formattingFunctions[column](value)
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.modify-field {
  padding: 3px;
  line-height: 1.5em;
  background-color: #7987ff;
  color: #fff;
  border-radius: 5px;
  &.old-value {
    background-color: #ed5e68;
  }
  &.new-value {
    background-color: #38b87d;
  }
}
.action-list {
  padding: 10px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  .action-item {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    &:not(:last-child) {
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
    }
  }
}
</style>
