// Custom Cell Renderer
import ProfitRenderer from '@/agGridV2/renderers/profit.renderer.vue'
import LinkRenderer from '../renderers/LinkRenderer.vue'
import SelectorColorRenderer from '../renderers/SelectorColorRenderer.vue'
import SelectorRenderer from '../renderers/SelectorRenderer.vue'
import WordsRenderer from '../renderers/WordsRenderer.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LinkRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SelectorColorRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SelectorRenderer,
    // eslint-disable-next-line vue/no-unused-components
    WordsRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ProfitRenderer
  }
}
