<template>
  <div class="projects-page">
    <tool-navigation />
    <div class="agGrid-page container">
      <div class="content-tool-header">
        <base-header>
          <div class="header-slot-items">
            <header-content-buttons
              :gridApi="gridApi"
              :delDisable="deleteButtonDisable"
              @add-rows="appendRow"
              @del-rows="deleteRows"
            />
          </div>
        </base-header>
        <div class="content-table-tools">
          <div class="content-tool-filters">
            <project-selector
              @value-change="projectFilterApply"
              v-if="!isLoading"
            />
            <record-status-selector
              :statuses="selectors.recordStatus"
              :currentStatus="selectedRecordStatus"
              @value-change="recordStatusFilterApply"
              v-if="!isLoading"
            />
            <div class="header-loading" v-else>Loading</div>
          </div>

          <div class="content-tools-buttons">
            <button
              class="button-outline-blue"
              id="comments-button"
              @click="showAllComments"
            >
              Comments
              <span class="badge">{{ commentsCount }}</span>
            </button>
            <button class="button-outline-blue" @click="showAllLogs">
              View Logs
            </button>
          </div>
        </div>
      </div>
      <!-- see ./mixins/logs.mixin.js -->
      <logs-view
        :logList="showingLogs"
        :isLoading="isLogsLoading"
        :isOpen="isShowLogs"
        :listType="logsOptions.type"
        @refresh="refreshLogs"
        @close="isShowLogs = false"
        @loadLogsPage="loadLogPage"
        @highlightRow="handleHighlightRow"
      />
      <!-- see ./mixins/comment.mixin.js -->
      <comments-view
        :comments="commentsToView"
        :isLoading="commentsLoading"
        :currentCell="currentCellComments || {}"
        :isOpen="isShowComments"
        @closeComments="isShowComments = false"
        @removeComment="removeComment"
        @highlightRow="handleHighlightRow"
        @highlightCells="highlightCells"
      />
      <div class="ag-grid-container">
        <div class="ag-table">
          <ag-grid-vue
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
            headerHeight="32"
            rowHeight="32"
            :gridOptions="gridOptions"
            :rowSelection="rowSelection"
            :enableRangeSelection="true"
            :enableFillHandle="true"
            :fillHandleDirection="'y'"
            :rowData="rowData"
            :columnDefs="columnDefs"
            :statusBar="statusBar"
            :getContextMenuItems="getContextMenuItems"
            :stopEditingWhenCellsLoseFocus="false"
            :suppressMovableColumns="true"
            @grid-ready="onGridReady"
            @filter-changed="stateEvents"
            @column-resized="stateEvents"
            @sort-changed="stateEvents"
            @column-visible="stateEvents"
            @column-pivot-changed="stateEvents"
            @column-row-group-changed="stateEvents"
            @column-value-changed="stateEvents"
            @column-moved="stateEvents"
            @column-pinned="stateEvents"
            @selectionChanged="onSelectionChange"
            @add-rows="appendRow"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Init components
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import { mapGetters, mapMutations } from 'vuex'

import ToolNavigation from '@/components/ToolNavigation.vue'
import AddRowStatusBar from './components/AddRowStatusBar.vue'
import HeaderContentButtons from './components/HeaderContentButtons.vue'
import ProjectSelector from './components/ProjectSelector.vue'
import RecordStatusSelector from './components/RecordStatusSelector.vue'
import { ContentToolSocket } from './services/ContentToolSocket'
import { getColumnDefs } from './utils/getColumnDefs'
import { getContextMenuItems } from './utils/getContextMenuItems'

// utils
import { CommentManager } from './utils/CommentManager'

// mixins
import CommentMixin from './mixins/comment.mixin'
import EditorMixin from './mixins/editors.mixin'
import LogsMixin from './mixins/logs.mixin'
import RenderMixin from './mixins/renders.mixin'

export default {
  mixins: [RenderMixin, CommentMixin, EditorMixin, LogsMixin],
  components: {
    BaseHeader,
    AgGridVue,
    ToolNavigation,
    // eslint-disable-next-line vue/no-unused-components
    AddRowStatusBar,
    HeaderContentButtons,
    ProjectSelector,
    RecordStatusSelector
  },
  data() {
    return {
      isLoading: true,
      rowData: null,
      columnDefs: null,
      gridApi: null,
      rowSelection: 'multiple',
      blockedRows: new Map(),
      selectedRecordStatus: 'All',
      gridOptions: {
        getRowId: (params) => {
          return params.data['_id']
        },
        onCellValueChanged: this.onCellValueChanged,
        onCellEditingStarted: this.cellEditingStarted,
        onCellEditingStopped: this.cellEditingStopped,
        processCellForClipboard: (params) => {
          let value
          if (!params.value) return
          if (typeof params.value === 'object') {
            value = JSON.stringify(params.value)
          } else value = params.value
          return value
        },
        defaultColDef: {
          enableCellChangeFlash: true,
          wrapText: true,
          autoHeight: true
        },
        context: {
          componentParent: this
        },
        getRowStyle: (params) => {
          const id = params.data._id
          if (params.data.blocked) {
            const client = this.blockedRows.get(id)
            const name =
              client.name.split(' ')[0] +
              ' ' +
              client.name.split(' ')[1][0] +
              '.'
            return {
              '--userHighlightColor': client.color,
              '--userHighlightName': `'${name}'`,
              '--userHighlightNameColor': this.getContrastColor(client.color)
            }
          }
        },
        rowClassRules: {
          blocked: (params) => params.data.blocked,
          'ordered-status': (params) =>
            params.data.recordStatus === 'Content Is Ordered',
          'need-check-status': (params) =>
            params.data.recordStatus === 'Need to Check',
          'have-questions': (params) =>
            params.data.recordStatus === 'Have Questions', // '#ebbdc3',
          assigned: (params) => params.data.recordStatus === 'Assigned' //'#fce8b2'
        }
      },
      records: null,
      projects: null,
      editors: null,
      selectors: null,
      statusBar: {
        statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent' }]
      },
      deleteButtonDisable: true,
      apiClient: null
    }
  },
  computed: {
    selectedProject() {
      return this.getSelectedProject()
    }
  },
  methods: {
    handleHighlightRow(rowId) {
      const node = this.gridApi.getRowNode(rowId)
      this.gridApi.ensureIndexVisible(node.rowIndex)
      this.gridApi.flashCells({ rowNodes: [node] })
      const rowEl = document.querySelector(`.ag-row[row-id="${rowId}"]`)
      rowEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    },
    getContrastColor(bgColor) {
      const color = bgColor.startsWith('#') ? bgColor.slice(1) : bgColor

      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)

      const yiq = (r * 299 + g * 587 + b * 114) / 1000

      return yiq > 150 ? '#000' : '#fff'
    },
    ...mapMutations(['setViewLoader', 'setSelectedProject']),
    ...mapGetters(['getSelectedProject', 'me', 'getApiClient']),
    onGridReady(params) {
      params.api.sizeColumnsToFit()
      this.gridApi = params.api
    },
    setState() {
      const state = this.$store.getters.getAgGridState(this.$route.name)
      if (state) {
        this.gridApi.applyColumnState({
          state: JSON.parse(state),
          applyOrder: true
        })
      }
      const stateFilter = this.$store.getters.getAgGridFilterChanged(
        this.$route.name
      )
      if (stateFilter) {
        this.gridApi.setFilterModel(JSON.parse(stateFilter))
      }
    },
    stateEvents(event) {
      this.gridApi.setColumnsVisible(
        ['project'],
        this.selectedProject._id === 'all'
      )
      if (event.type === 'filterChanged') {
        this.$store.dispatch('setAgGridFilterChanged', {
          page: this.$route.name,
          query: JSON.stringify(this.gridApi.getFilterModel())
        })
      } else {
        this.$store.dispatch('setAgGridState', {
          page: this.$route.name,
          query: JSON.stringify(this.gridApi.getColumnState())
        })
      }
    },
    copyRowData(data) {
      return data.map((datum) => {
        return {
          ...datum
        }
      })
    },
    getContextMenuItems(params) {
      return getContextMenuItems(params, this.apiClient, this)
    },
    generateColumnDefs: ({ projects, editors, selectors }) => {
      const colDef = [...getColumnDefs({ projects, editors, selectors })]
      return colDef
    },
    async onCellValueChanged(event) {
      const { oldValue, newValue, colDef, node } = event
      if (
        oldValue === newValue ||
        (oldValue === undefined && newValue === null)
      )
        return

      const { field } = colDef
      const { id } = node
      if (!field || !id) return
      let data = { [field]: newValue }
      if (field === 'project') {
        data['projectManager'] = node.data.projectManager
      }
      try {
        const res = await this.apiClient.records.update(id, data)
        const { __v, ...resData } = res
        node.setData(resData)
        if (
          field === 'finalContent' &&
          (!resData.words || resData.words === 0)
        ) {
          const { __v, ...updatedData } =
            await this.apiClient.records.countWords(id, resData.finalContent)
          node.setData(updatedData)
        }
      } catch (err) {
        this.gridApi.refreshCells({
          rowNodes: [node],
          columns: [field]
        })
        console.error(err)
      }
    },
    async projectFilterApply() {
      const value = this.selectedProject
      const filterInstance = await this.gridApi.getColumnFilterInstance(
        'project'
      )
      if (!filterInstance) {
        return
      }
      if (value._id === 'all') {
        this.gridApi.setColumnsVisible(['project'], true)
        filterInstance.setModel(null)
      } else {
        this.gridApi.setColumnsVisible(['project'], false)
        filterInstance.setModel({
          filterType: 'set',
          values: [value._id]
        })
      }
      this.gridApi.onFilterChanged()
    },
    async recordStatusFilterApply(value) {
      if (value) {
        this.selectedRecordStatus = value
      }
      const filterInstance = await this.gridApi.getColumnFilterInstance(
        'recordStatus'
      )
      if (!filterInstance) {
        return
      }
      if (this.selectedRecordStatus === 'All') {
        this.gridApi.setColumnsVisible(['recordStatus'], true)
        filterInstance.setModel(null)
      } else {
        this.gridApi.setColumnsVisible(['recordStatus'], false)
        filterInstance.setModel({
          filterType: 'set',
          values: [this.selectedRecordStatus]
        })
      }
      this.gridApi.onFilterChanged()
    },
    async filterApply() {
      await this.projectFilterApply()
      await this.recordStatusFilterApply()
    },
    appendRow(rowCount) {
      if (this.selectedProject._id === 'all') {
        alert('Select a project before creating a row')
        return
      }
      const project = this.projects.find(
        (p) => p._id === this.selectedProject._id
      )
      if (!project) {
        alert('Error with project creation. Check console.')
        console.error(
          'Project not found! Project Selector Data: ',
          this.selectedProject
        )
        return
      }
      const createdArr = []
      for (let i = 0; i < rowCount; i++) {
        createdArr.push({
          project,
          projectManager: { _id: project.pm._id, name: project.pm.name }
        })
      }
      this.apiClient.records
        .create(createdArr)
        .then((data) => {
          this.gridApi.applyTransaction({ add: data })
          this.$nextTick(() => {
            this.filterApply()
          })
        })
        .catch((err) => console.error(err))
    },
    deleteRows(selectedRows) {
      let blockedSelect = false
      selectedRows.forEach((row) => {
        blockedSelect = blockedSelect || ('blocked' in row && row['blocked'])
      })
      if (blockedSelect) {
        alert('You select a blocked row')
        this.gridApi.deselectAll()
        return false
      }
      const isAllowed = confirm(
        `Are you sure you want to delete ${selectedRows.length} records?`
      )
      if (isAllowed) {
        const ids = []
        selectedRows.forEach((row) => ids.push(row['_id']))
        this.apiClient.records
          .removeMany(ids)
          .then((res) => {
            this.gridApi.applyTransaction({ remove: selectedRows })
            this.deleteButtonDisable = true
          })
          .catch((err) => alert(err))
      }
    },
    onSelectionChange() {
      const selectedRows = this.gridApi.getSelectedRows()
      this.deleteButtonDisable = selectedRows.length === 0
    },
    setKeyDownEvent() {
      document.addEventListener('keydown', (event) => {
        if (!event.ctrlKey || event.code !== 'KeyF') {
          return
        }
        const search = document.querySelector('#filter-text-box')
        if (!search) {
          return
        }
        event.preventDefault()
        search.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        })
        setTimeout(() => search.focus(), 100)
      })
    }
  },
  async beforeMount() {
    this.$emitter.on('header_search_addon_changed', () => {
      this.gridApi.setGridOption(
        'quickFilterText',
        this.$store.getters.getSearchQuery(this.$route.name)
      )
    })
    this.$emitter.on('header_reset_state', () => {
      this.gridApi.resetColumnState()
      const timeout = setTimeout(() => {
        this.$store.dispatch('setAgGridState', {
          page: this.$route.name,
          query: ''
        })
        clearTimeout(timeout)
      }, 10)
    })
    this.$emitter.on('header_reset_filters', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.setFilterModel(null)
        this.gridApi.setGridOption('quickFilterText', null)
        this.setSelectedProject({ _id: 'all', name: 'All' })
        this.gridApi.setColumnsVisible(['project'], true)
        const timeout = setTimeout(() => {
          this.$store.dispatch('setAgGridFilterChanged', {
            page: this.$route.name,
            query: ''
          })
          clearTimeout(timeout)
        }, 10)
      }
    })
  },
  mounted() {
    ;(async () => {
      this.setViewLoader(true)
      // Authorize
      await this.$store.dispatch('authClient')
      this.apiClient = this.getApiClient()

      await this.$store.dispatch('loadProjects')
      // Getting data for table
      const [records, projects, editors, selectors] = await Promise.all([
        this.apiClient.records.get(),
        this.apiClient.projects.get(),
        this.apiClient.editors.get(),
        this.apiClient.selectors.get()
      ])

      this.projects = projects
      this.records = records
      this.editors = editors
      this.selectors = selectors
      await this.loadComments()

      // Initialize grid
      this.columnDefs = Object.freeze(
        this.generateColumnDefs({
          projects,
          editors,
          selectors
        })
      )

      this.$nextTick(() => {
        this.gridApi.setRowData(records)
        this.gridApi.setQuickFilter(
          this.$store.getters.getSearchQuery(this.$route.name)
        )
        this.setState()
        this.filterApply()

        // Initialize socket manager
        this.socketManager = new ContentToolSocket(this)
        this.socketManager.init()

        // From comment.mixin.js
        this.commentManager = new CommentManager(this.apiClient.comments)

        this.setViewLoader(false)
        this.isLoading = false

        const query = { ...this.$route.query }
        const showRow = query['show-row']
        if (showRow && this.gridApi.getRowNode(showRow)) {
          this.handleHighlightRow(showRow)
          delete query['show-row']
          this.$router.replace({ query })
        }
      })
    })()
  }
}
</script>

<style lang="scss">
@import './style/main.scss';
</style>
