export class ContentToolSocket {
  constructor(component) {
    this.component = component
    this.gridApi = component.gridApi
    this.blockedRows = component.blockedRows

    // Привязываем методы к контексту класса
    this.socketHandler = this.socketHandler.bind(this)
    this.cellEditingStarted = this.cellEditingStarted.bind(this)
    this.cellEditingStopped = this.cellEditingStopped.bind(this)
  }
  commentActions = {
    create: (data) => {
      if (this.component.commentsAll.length === 0) {
        return
      }
      this.commentsLoading = true
      this.component.commentsAll = [...data, ...this.component.commentsAll]
      this.commentsLoading = false
    },
    update: (data) => {
      console.log('update', data)
    },
    remove: (data) => {
      this.component.commentsAll = this.component.commentsAll.filter(
        (comment) => comment.id !== data._id
      )
    }
  }
  // Обработчики сокет-событий
  socketActions = {
    lockCell: ({ cell, client }) => {
      this.blockedRows.set(cell.rowId, client)
      const node = this.gridApi.getRowNode(cell.rowId)
      node.data['blocked'] = true
      this.gridApi.redrawRows({
        rowNodes: [node]
      })
    },
    unlockCell: ({ cell }) => {
      this.blockedRows.delete(cell.rowId)
      const node = this.gridApi.getRowNode(cell.rowId)
      node.data['blocked'] = false
      this.gridApi.redrawRows({
        rowNodes: [node]
      })
    },
    modify: async ({ action, data }) => {
      this.gridApi.applyTransaction({ [action]: data })
      if (this.component.isShowLogs) {
        this.component.isLogsLoading = true
        await this.component.refreshLogs()
        this.component.isLogsLoading = false
      }
    },
    comment: ({ action, data }) => {
      console.log(data)
      const commentAction = this.commentActions[action]
      if (commentAction) {
        commentAction(data)
      } else {
        console.error('Socket comment action not found:', action)
      }
    },
    comment_update: ({ data }) => {
      console.log(data)
      // this.component.commentsAll = this.component.commentsAll.map((item) => {
      //   if (item._id === data[0]._id) {
      //     return data[0]
      //   }
      //   return item
      // })
      // this.component.commentsToView = this.component.commentsToView.map((item) => {
      //   if (item._id === data[0]._id) {
      //     return data[0]
      //   }
      //   return item
      // })
    },
    comment_remove: ({ data }) => {
      console.log(data)
      // this.component.commentsAll = this.component.commentsAll.filter((item) => {
      //   return item._id !== data[0]._id
      // })
      // this.component.commentsToView = this.component.commentsToView.filter((item) => {
      //   return item._id !== data[0]._id
      // })
    }
  }

  // Вспомогательные методы
  getCell(eventData) {
    const { colDef, node } = eventData
    const field = colDef.field
    const rowId = node.id
    return { colId: field, rowId }
  }

  // Методы для работы с ячейками
  lockCell(cell) {
    if (this.component.apiClient?.socket) {
      this.component.apiClient.socket.send(
        JSON.stringify({
          type: 'lockCell',
          data: { cell }
        })
      )
    }
  }

  unlockCell(cell) {
    if (this.component.apiClient?.socket) {
      this.component.apiClient.socket.send(
        JSON.stringify({
          type: 'unlockCell',
          data: { cell }
        })
      )
    }
  }

  // Обработчики событий редактирования
  cellEditingStarted(event) {
    this.lockCell(this.getCell(event))
  }

  cellEditingStopped(event) {
    this.unlockCell(this.getCell(event))
    if (event.colDef.field === 'words') {
      event.colDef.editable = false
    }
  }

  // Основной обработчик сокет-сообщений
  socketHandler(event) {
    try {
      const message = JSON.parse(event.data)
      console.log(message)
      const action = this.socketActions[message.type]

      if (action) {
        action(message.data)
      } else {
        console.error('Socket action not found:', message.type)
      }
    } catch (error) {
      console.error('Error handling socket message:', error)
    }
  }

  // Обновление ячейки words
  async refreshWordsCell(recordId, finalContent) {
    try {
      const { __v, ...resData } =
        await this.component.apiClient.records.countWords(
          recordId,
          finalContent
        )
      const node = this.gridApi.getRowNode(recordId)
      node.setData(resData)
    } catch (error) {
      console.error('Error refreshing words cell:', error)
    }
  }

  // Инициализация и очистка
  init() {
    this.component.apiClient.socketConnect(this.socketHandler)
  }
}
