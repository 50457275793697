import CommentsView from '../components/CommentsView/CommentsView.vue'

export default {
  components: { CommentsView },
  data() {
    return {
      commentsAll: [],
      // commentsToView: [],
      commentsLoading: false,
      currentCellComments: null,
      commentManager: null,
      isShowComments: false
    }
  },
  computed: {
    commentsCount() {
      return this.commentsAll.length
    },
    commentsToView() {
      console.log('this.currentCellComments', this.currentCellComments)
      if (this.currentCellComments) {
        return this.currentCellComments.comments
      } else {
        return this.commentsAll
      }
    }
  },
  methods: {
    showCommentInput(top, left, cells) {
      this.commentManager.createCommentBlock(top, left, cells, this.saveComment)
    },
    async saveComment(commentText, cells) {
      const { status, comment } = this.commentManager.saveComment(
        commentText,
        cells
      )
      if (status) {
        this.commentsAll = [comment, ...this.commentsAll]
      }
    },
    async showCellComments(rowId, colId) {
      this.commentsLoading = true
      this.isShowComments = true
      const comments = await this.commentManager.getCellComments(rowId, colId)
      this.currentCellComments = {
        recordId: rowId,
        fieldName: colId,
        comments: comments
      }
      this.commentsLoading = false
    },
    async removeComment(id) {
      this.commentsLoading = true
      const status = this.commentManager.removeComment(id)
      if (status) {
        this.commentsAll = this.commentsAll.filter(
          (comment) => comment._id !== id
        )
      }
      this.commentsLoading = false
    },
    async showAllComments() {
      this.isShowComments = true
      this.currentCellComments = null
    },
    async loadComments() {
      this.commentsLoading = true
      this.commentsAll = await this.apiClient.comments.getAll()
      this.commentsLoading = false
    },
    highlightCells(cells) {
      console.log(cells)
      const rows = new Map()
      cells.forEach((cell) => {
        if (!rows.has(cell.recordId)) {
          rows.set(cell.recordId, [cell.fieldName])
        } else {
          rows.get(cell.recordId).push(cell.fieldName)
        }
      })

      rows.forEach((columns, rowId) => {
        const node = this.gridApi.getRowNode(rowId)
        this.gridApi.flashCells({
          rowNodes: [node],
          columns: columns
        })
      })
      const rowEl = document.querySelector(
        `.ag-row[row-id="${rows.keys().next().value}"]`
      )
      rowEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    }
  }
}
