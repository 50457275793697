import {
  contentTypeColor,
  priorityColor,
  statusColor
} from '../config/selectorsColors'

export function getColumnDefs({ projects, selectors, editors }) {
  const editorsValues = []
  const editorsKeyToValue = {}
  if (editors && editors.length > 0) {
    for (const editor of editors) {
      editorsValues.push(editor['_id'])
      editorsKeyToValue[editor['_id']] = editor['name']
    }
  }

  return [
    {
      field: 'id',
      hide: true,
      sort: 'desc'
    },
    {
      field: 'recordStatus',
      headerName: 'Record Status',
      editable: true,
      cellEditor: 'SelectorEditor',
      cellRenderer: 'SelectorRenderer',
      cellEditorParams: {
        values: selectors.recordStatus.filter((status) => status !== 'New')
      },
      filter: 'agSetColumnFilter'
    },
    {
      headerName: 'Project',
      field: 'project',
      cellEditorParams: {
        values: projects
      },
      valueFormatter: (params) => {
        if (!params.value) {
          return 'Choose Project'
        }
        const { abbr, type } = params.value
        return `${abbr}-${type}`
      },

      filter: 'agSetColumnFilter',
      filterParams: {
        keyCreator: (params) => (params.value ? params.value._id : null),
        valueFormatter: (params) => {
          if (!params.value) {
            return '(Blanks)'
          }
          const { abbr, type } = params.value
          return `${abbr}-${type}`
        }
      },
      getQuickFilterText: (params) => {
        if (!params.value) {
          return '(Blanks)'
        }
        const { abbr, type } = params.value
        return `${abbr}-${type}`
      }
    },
    {
      headerName: 'Category',
      field: 'category',
      editable: true,
      cellEditor: 'SelectorEditor',
      cellRenderer: 'SelectorRenderer',
      cellEditorParams: {
        values: selectors.category
      }
    },
    {
      headerName: 'Task Title',
      field: 'taskTitle',
      editable: true,
      cellEditor: 'agTextCellEditor',
      width: 250
    },
    {
      headerName: 'Type',
      field: 'contentType',
      editable: true,
      cellEditor: 'SelectorEditor',
      cellEditorParams: { values: selectors.contentType },
      cellRenderer: 'SelectorColorRenderer',
      cellRendererParams: { values: contentTypeColor }
    },
    {
      headerName: 'Priority',
      field: 'priority',
      editable: true,
      cellEditor: 'SelectorEditor',
      cellEditorParams: { values: selectors.priority },
      cellRenderer: 'SelectorColorRenderer',
      cellRendererParams: { values: priorityColor, default: 'Normal' }
    },
    {
      headerName: 'Deadline',
      field: 'deadline',
      editable: true,
      cellEditor: 'DeadlineEditor',
      cellRenderer: 'DeadlineEditor'
    },
    // {
    //   headerName: 'Status from Hanna',
    //   field: 'statusHanna',
    //   editable: true,
    //   cellEditor: 'SelectorEditor',
    //   cellEditorParams: { values: selectors.statusHanna },
    //   cellRenderer: 'SelectorColorRenderer',
    //   cellRendererParams: { values: statusColor }
    // },
    {
      headerName: 'Editor',
      field: 'editor',
      editable: true,
      cellEditor: 'SelectorEditor',
      cellEditorParams: {
        values: editors,
        label: 'name',
        reduce: (item) => item._id
      },
      cellRenderer: 'SelectorRenderer',
      valueFormatter: (params) => {
        if (!params.value) return 'Choose Editor'
        return editorsKeyToValue[params.value]
      }
    },
    {
      headerName: 'Spec Sheet',
      field: 'specLink',
      editable: true,
      width: 120,
      cellEditor: 'agTextCellEditor',
      cellRenderer: 'LinkRenderer'
    },
    {
      headerName: 'Final Content',
      field: 'finalContent',
      editable: true,
      width: 120,
      cellEditor: 'agTextCellEditor',
      cellRenderer: 'LinkRenderer'
    },
    {
      headerName: 'Page Link',
      field: 'pageLink',
      editable: true,
      cellEditor: 'agTextCellEditor',
      cellRenderer: 'LinkRenderer'
    },
    {
      headerName: 'Words',
      field: 'words',
      editable: false,
      cellRenderer: 'WordsRenderer'
    },
    {
      headerName: 'Uniqueness',
      field: 'uniqueness',
      editable: false,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100
      },
      valueFormatter: (p) => +(p.value ?? 0) + '%'
    },
    {
      headerName: 'Pack',
      field: 'pack',
      editable: true,
      cellEditor: 'PackEditor',
      cellRenderer: 'PackEditor'
    },
    {
      headerName: 'PM',
      field: 'projectManager',
      editable: false,
      cellEditor: 'agTextCellEditor',
      valueGetter: (params) => {
        const { project } = params.data
        return project ? project.pm : null
      },
      valueFormatter: (params) => {
        return params.value ? params.value.name : 'not found'
      }
    },
    {
      headerName: 'Status for PM',
      field: 'statusPM',
      editable: true,
      cellEditor: 'SelectorEditor',
      cellEditorParams: { values: selectors.statusPM },
      cellRenderer: 'SelectorColorRenderer',
      cellRendererParams: { values: statusColor }
    },
    {
      headerName: 'Issues/Comments',
      field: 'comments',
      editable: true,
      cellEditor: 'RichTextEditor',
      cellEditorPopup: true,
      cellRenderer: (params) => {
        const wrapper = document.createElement('div')
        wrapper.className = 'rich-text-renderer'
        wrapper.innerHTML = params.value || ''
        return wrapper
      },
      width: 150,
      autoHeight: true
    }
  ]
}

function createLink(href) {
  const res = href.match(/(https?:\/\/[^\s]+)/g)
  if (res === null) return 'Invalid URL'
  return `<a href=${href} target="_blank" class="cell_link">${href}</a>`
}
