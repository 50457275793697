export class CommentManager {
  // Создаем форму для ввода комментария
  commentForm = `
  <div class="comment-form">
    <textarea
      class="comment-form__input"
      placeholder="Input your comment..."
      rows="3"
    ></textarea>
    <div class="comment-form__footer">
      <button class="comment-form__button comment-form__button--cancel">Cancel</button>
      <button class="comment-form__button comment-form__button--submit">Save</button>
    </div>
  </div>
`
  isRemoving = false
  boundHandleClickOutside = null

  constructor(commentsApi) {
    this.activeBlock = null
    this.commentsApi = commentsApi
    console.log(this.commentsApi)
  }

  async createCommentBlock(top, left, rows, onSave) {
    // Удаляем предыдущий блок, если он существует
    if (this.activeBlock) {
      await this.removeBlock()
    }
    console.log('comment started create')

    const block = document.createElement('div')
    block.className = 'comment-block'

    // Получаем размеры окна и прокрутки
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const scrollX = window.scrollX
    const scrollY = window.scrollY

    // Добавляем блок в DOM для получения его размеров
    block.style.visibility = 'hidden'
    block.innerHTML = this.commentForm
    document.body.appendChild(block)
    const blockHeight = block.offsetHeight
    const blockWidth = block.offsetWidth

    // Вычисляем позицию с учетом прокрутки
    let finalTop = top + scrollY
    let finalLeft = left + scrollX

    // Проверяем, не выходит ли блок за пределы экрана снизу
    if (top + blockHeight > windowHeight) {
      finalTop = top - blockHeight
    }

    // Проверяем, не выходит ли блок за пределы экрана справа
    if (left + blockWidth > windowWidth) {
      finalLeft = left - blockWidth
    }

    // Применяем финальные координаты
    block.style.top = `${finalTop}px`
    block.style.left = `${finalLeft}px`
    block.style.visibility = 'visible'

    // Сохраняем ссылку на активный блок
    this.activeBlock = block

    // Добавляем класс для анимации
    setTimeout(() => {
      block.classList.add('comment-block--visible')
    }, 0)

    // Находим элементы формы
    const textarea = block.querySelector('.comment-form__input')
    const submitButton = block.querySelector('.comment-form__button--submit')
    const cancelButton = block.querySelector('.comment-form__button--cancel')

    // Добавляем обработчики событий
    submitButton.addEventListener('click', () => {
      const commentText = textarea.value.trim()
      if (commentText) {
        onSave(commentText, rows)
        this.removeBlock()
      }
    })

    cancelButton.addEventListener('click', () => {
      this.removeBlock()
    })

    // Фокус на текстовое поле
    setTimeout(() => {
      textarea.focus()
    }, 100)

    this.boundHandleClickOutside = this.handleClickOutside.bind(this)
    setTimeout(() => {
      document.addEventListener('click', this.boundHandleClickOutside)
    }, 100)
  }

  handleClickOutside(event) {
    if (
      this.activeBlock &&
      !this.activeBlock.contains(event.target) &&
      !this.isRemoving
    ) {
      this.removeBlock()
      console.log('event removed')
    }
  }

  removeBlock() {
    console.log('comment started remove', this.activeBlock)
    if (this.activeBlock) {
      this.isRemoving = true
      this.activeBlock.classList.remove('comment-block--visible')
      document.removeEventListener('click', this.boundHandleClickOutside)
      this.boundHandleClickOutside = null
      return new Promise((resolve) => {
        setTimeout(() => {
          if (this.activeBlock && this.activeBlock.parentNode) {
            document.body.removeChild(this.activeBlock)
          }
          this.activeBlock = null
          this.isRemoving = false
          resolve()
        }, 300)
      })
    }
  }
  async getCellComments(rowId, colId) {
    try {
      console.log(
        'Fetching cell comments for rowId:',
        rowId,
        'and colId:',
        colId
      )
      const comments = await this.commentsApi.getByRange([
        { recordId: rowId, fieldName: colId }
      ])
      return comments
    } catch (error) {
      console.error('Error while fetching cell comments:', error)
    }
  }
  async saveComment(commentText, cells) {
    try {
      console.log('Saving comment:', { commentText, cells })
      const data = await this.commentsApi.create({
        comment: commentText,
        cells
      })
      return { status: true, comment: data }
    } catch (error) {
      console.error('Error while saving comment:', error)
      return false
    }
  }
  async removeComment(commentId) {
    try {
      await this.commentsApi.remove(commentId)
      return true
    } catch (error) {
      console.error('Error while removing comment:', error)
      return false
    }
  }
}
