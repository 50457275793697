import LogsView from '../components/LogsView/LogsView.vue'
export default {
  components: { LogsView },
  data() {
    return {
      logList: [],
      showingLogs: [],
      isLogsLoading: false,
      logsPage: 1,
      isShowLogs: false,
      logsOptions: { type: 'all', position: null } // all || one
    }
  },
  methods: {
    async loadLogPage(isNewPage) {
      if (this.isLogsLoading) return
      this.isLogsLoading = true
      if (isNewPage) {
        this.logsPage += 1
      }
      this.showingLogs = [
        ...this.logList,
        ...(await this.apiClient.logs.get(this.logsPage))
      ]
      this.isLogsLoading = false
    },
    async refreshLogs() {
      if (this.logsOptions.type === 'all') {
        return await this.openLogs()
      } else {
        const { row, column } = this.logsOptions.position
        return await this.loadCellLogs(row, column)
      }
    },
    async loadCellLogs(row, column) {
      this.isLogsLoading = true
      this.logsOptions.type = 'one'
      this.logsOptions.position = {
        row,
        column
      }
      const data = await this.apiClient.logs.getByCell(row, column)
      this.showingLogs = data
      this.isLogsLoading = false
    },
    // button
    async showAllLogs() {
      this.isShowLogs = true
      this.isLogsLoading = true
      this.logsOptions.type = 'all'
      this.logsOptions.position = null
      this.logList = []
      for (let page = 1; page <= this.logsPage; page++) {
        this.logList = [
          ...this.logList,
          ...(await this.apiClient.logs.get(page))
        ]
      }
      this.showingLogs = [...this.logList]
      console.log(this.showingLogs)
      this.isLogsLoading = false
    }
  }
}
