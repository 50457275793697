export function getContextMenuItems(params, apiClient, context) {
  const { api, node, column } = params
  if (!node) return
  const id = node?.id ?? undefined
  const project =
    node && node.data.project
      ? `${node.data.project.abbr}-${node.data.project.type}`
      : undefined
  const selectedRows = api.getSelectedRows()
  let blockedSelect = false
  selectedRows.forEach((row) => {
    blockedSelect = blockedSelect || ('blocked' in row && row['blocked'])
  })
  if (blockedSelect || node.data['blocked']) {
    alert('You select a blocked row')
    api.deselectAll()
    return false
  }
  const result = [
    'copy',
    'paste',
    'separator',
    {
      name: 'View Edit History',
      action: async () => {
        await context.loadCellLogs(id, column.colId)
        context.isShowLogs = true
      }
    },
    {
      name: 'View Comments',
      action: async () => {
        context.showCellComments(id, column.colId)
      }
    },
    {
      name: 'New Comment',
      action: async () => {
        const cells = []

        const ranges = api.getCellRanges()
        for (const range of ranges) {
          const { columns, startRow, endRow } = range
          const startRowIndex = startRow.rowIndex
          const endRowIndex = endRow.rowIndex
          for (let i = startRowIndex; i <= endRowIndex; i++) {
            const node = api.getDisplayedRowAtIndex(i)
            for (const col of columns) {
              cells.push({
                fieldName: col.colId,
                recordId: node.id
              })
            }
          }
        }
        const rowEl = document.querySelector(`.ag-row[row-id="${node.id}"]`)
        const cellEl = rowEl.querySelector(`.ag-cell[col-id="${column.colId}"]`)
        const rect = cellEl.getBoundingClientRect()
        context.showCommentInput(rect.top, rect.left, cells)
      }
    },
    'separator',
    {
      name: 'Get Row Link',
      action: async () => {
        navigator.clipboard.writeText(window.location.href + `?show-row=${id}`)
      }
    },
    'separator',
    {
      name: 'Clean Up',
      disabled: !node,
      action: async () => {
        const index = node.rowIndex !== null ? node.rowIndex + 1 : 0
        const isAllowed = confirm(
          `Are you sure you want to clean all data from the ${
            project ?? index
          } project entry?`
        )
        if (isAllowed) {
          const data = [{ _id: id, project: node.data.project }]
          apiClient.records
            .clean(data)
            .then((res) => api.applyTransaction({ update: data }))
            .catch((err) => alert(err))
        } else {
          params.node.setSelected(false)
        }
      }
    },
    {
      name: 'Clean Up All Selected',
      disabled: selectedRows.length < 2,
      action: async () => {
        const isAllowed = confirm(
          `Are you sure you want to clean up ${selectedRows.length} records?`
        )
        if (isAllowed) {
          const idsList = selectedRows.map((row) => {
            return { _id: row._id, project: row.project }
          })
          apiClient.records
            .clean(idsList)
            .then((res) => api.applyTransaction({ update: idsList }))
            .catch((err) => alert(err))
        }
      }
    },
    'separator',
    {
      name: 'Delete',
      disabled: !node,
      action: async () => {
        const index = node.rowIndex !== null ? node.rowIndex + 1 : 0
        const isAllowed = confirm(
          `Are you sure you want to delete the ${
            project ?? index
          } project entry?`
        )
        if (isAllowed) {
          apiClient.records
            .remove(id)
            .then((res) => api.applyTransaction({ remove: [{ _id: id }] }))
            .catch((err) => alert(err))
        } else {
          params.node.setSelected(false)
        }
      }
    },
    {
      name: 'Delete All Selected',
      disabled: selectedRows.length < 2,
      action: async () => {
        const isAllowed = confirm(
          `Are you sure you want to delete ${selectedRows.length} records?`
        )
        if (isAllowed) {
          const ids = []
          selectedRows.forEach((row) => ids.push(row['_id']))
          apiClient.records
            .removeMany(ids)
            .then((res) => api.applyTransaction({ remove: selectedRows }))
            .catch((err) => alert(err))
        }
      }
    }
  ]

  return result
}
