// TODO: append button view row or rows

<template>
  <div class="comments-wrap" :class="{ open: isOpen }">
    <div class="comments-header">
      <h3 class="header">Comments</h3>
      <button class="button-outline-blue close-btn" @click="closeComments">
        Close
      </button>
    </div>
    <ul class="comments-list" v-if="comments.length > 0">
      <li class="comment-item" v-for="comment in comments" :key="comment.id">
        <div class="comment-item__position">
          <button
            @click="$emit('highlightCells', comment.cells)"
            v-if="Object.keys(currentCell).length === 0"
            class="button-outline-blue view-cells-btn"
          >
            View Cell(s)
          </button>
          <div v-else>
            {{ commentsPosition(comment) }}
          </div>
        </div>
        <div class="comment-item-header">
          <div class="comment-item-header__info">
            <div class="comment-item__name">{{ comment.author.name }}</div>
            <div class="comment-item__date">
              {{ formattedDates[comment._id] }}
            </div>
          </div>
          <button
            class="close-comment-btn"
            @click="removeComment(comment._id)"
          ></button>
        </div>
        <div class="comment-item__body">{{ comment.comment }}</div>
      </li>
    </ul>
    <div class="" v-else-if="comments.length === 0">Comments not found</div>
    <div class="loader" v-else-if="isLoading"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'comments-view',
  data() {
    return {
      user: null
    }
  },
  props: {
    comments: {
      type: Array,
      required: true
    },
    currentCell: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    formattedDates() {
      const dates = {}
      this.comments.forEach((comment) => {
        const dateTime = new Date(comment.createdDate).toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          month: 'short',
          day: 'numeric'
        })
        const [date, time] = dateTime.split(', ')
        dates[comment._id] = `${time} ${date}`
      })
      return dates
    }
  },
  methods: {
    ...mapGetters(['me']),
    removeComment(id) {
      const isAllowed = confirm('Are you sure?')
      if (!isAllowed) return
      this.$emit('removeComment', id)
    },
    closeComments() {
      this.$emit('closeComments')
    },
    commentsPosition(comment) {
      console.log(this.currentCell)
      const cell = comment.cells.filter((cell) => {
        return (
          cell.fieldName === this.currentCell.fieldName &&
          cell.recordId === this.currentCell.recordId
        )
      })[0]
      return `${cell.project} | ${cell.fieldName}`
    }
  },
  mounted() {
    this.user = this.me()
  }
}
</script>

<style lang="scss" scoped>
.comments-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 400px;
  background-color: #f4f5ff;
  padding: 15px;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s linear;
  transform: translateX(100%);
  overflow: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  &.open {
    transform: translateX(0%);
  }
}
.header {
  margin: 0;
}
.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
* {
  font-family: inherit;
}
.comments-list {
  background-color: #fff;
  padding: 10px 5px;
  margin: 0;
  list-style-type: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow: auto;
  flex-grow: 1;
}
.comment {
  &-item {
    background-color: #f4f5ff;
    padding: 10px;
    border-radius: 5px;
    &__position {
      font-size: 11px;
      line-height: 1.1em;
      margin-bottom: 6px;
    }
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      &__buttons {
        display: flex;
        column-gap: 3px;
        button {
          width: 25px;
          height: 25px;
          padding: 0;
          margin: 0;
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__name {
      margin-bottom: 3px;
    }
    &__date {
      font-size: 12px;
      font-weight: 500;
    }
    &__body {
      padding: 0 5px;
    }
  }
}
.close-btn {
  border-color: #ed5e68;
  color: #ed5e68;
  &:hover {
    background-color: #ed5e68;
    color: #fff;
  }
}
.close-comment-btn {
  width: 40px;
  height: 40px;
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.25s ease;
  &:hover {
    background-color: #eee;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 3px;
    background-color: #38b87d;
    transform-origin: center;
    border-radius: 2px;
  }
  &::before {
    width: 11px;
    top: 50%;
    left: calc(50% - 11px);
    transform-origin: right;
    transform: translateY(175%) rotate(52.5deg);
  }
  &::after {
    width: 15.5px;
    top: 50%;
    left: calc(50% - 1px);
    transform-origin: left;
    transform: translateY(175%) rotate(-52.5deg);
  }
}
.view-cells-btn {
  font-size: 12px;
  line-height: 1.1em;
  padding: 5px 10px;
  min-height: auto;
  display: block;
}
// loader
.loader {
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 black;
  position: absolute;
  clip-path: inset(-40px 0 -5px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader:before {
  content: '';
  position: absolute;
  inset: auto calc(50% - 17px) 0;
  height: 50px;
  --g: no-repeat linear-gradient(#7987ff 0 0);
  background: var(--g), var(--g), var(--g), var(--g);
  background-size: 16px 14px;
  animation: l7-1 2s infinite linear, l7-2 2s infinite linear;
}
@keyframes l7-1 {
  0%,
  100% {
    background-position: 0 -50px, 100% -50px;
  }
  17.5% {
    background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px;
  }
  35% {
    background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px;
  }
  52.5% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px;
  }
  70%,
  98% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px),
      100% calc(100% - 16px);
  }
}
@keyframes l7-2 {
  0%,
  70% {
    transform: translate(0);
  }
  100% {
    transform: translate(200%);
  }
}
</style>
